import React from "react";

const Footer = ({ designInfo }) => {
  return (
    <>
      <div className="w-full px-[50px] cflexmm gap-[64px] pb-[100px] bg-white text-white">
        <div className="w-full cflexmm text-center rounded-[8px] p-[56px] gap-[10px] bg-[#20231F]">
          <p className="text-[24px] font-bold">Still have a question?</p>
          <p className="text-[18px]">
            Our friendly support team is here for you.
          </p>
          {designInfo.footerContactUsCard === "true" && (
            <button className="px-[16px] py-[10px] text-[14px] font-medium rounded-[8px] flexmm bg-[#1F2023] hover:bg-[#1F2023]/40 border border-[#4D4E51] outline-none">
              Contact Us
            </button>
          )}
        </div>
        {designInfo.footerAlpsLink === "true" && (
          <p className="text-[14px] text-[#7C7D7E] cursor-pointer">
            Powered by Alps
          </p>
        )}
      </div>
    </>
  );
};

export default Footer;
