import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Plus } from "heroicons-react";
import CategoryContent from "./CategoryContent";
import { getUserCategories, reorderCategory } from "../../services/request";
import NavBar from "./NavBar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import Button from "../../components/Button";

const GeneralContent = () => {
  const { collectionID } = useParams();
  const [view, setView] = useState("");
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [content, setContent] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [position, setPosition] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [user, setUser] = useState("");
  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const categoryReorder = async (newItems) => {
    let alpsToken = localStorage.getItem("alpsToken");
    let customized = [];
    for (let i = 0; i < newItems.length; i++) {
      customized.push({
        id: newItems[i].id,
        order: i + 1,
      });
    }
    await reorderCategory(alpsToken, { categories: customized });
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, newIndex) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const newItems = [...categories];
    const [removed] = newItems.splice(draggedIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setCategories(newItems);
    setPosition(newIndex);
    categoryReorder(newItems);
  };

  const getCategories = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    }

    let data;
    if (alpsToken) {
      setLoading(true);
      data = await getUserCategories(alpsToken);
      if (data) {
        setCategories(data);
        if (collectionID) {
          let category = data.find((item) => item.id === collectionID);
          if (category) {
            setContent(category);
            setView("create");
          }
        } else if (position >= 0 && !collectionID) {
          setContent(data[position]);
          setView("create");
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
    if (position === -1) {
      setView("");
      setContent("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionID]);

  const filteredCategories = categories?.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <NavBar active="Collections" />
      <div className={`w-full flexss h-full font-inter mt-[80px]`}>
        <div className="w-[320px] h-[90vh] cflexss gap-[30px] pb-[40px] border-r-[2px] text-primary1 flex-shrink overflow-y-auto">
          <div className="w-full px-[20px] py-[16px] flexbm gap-[10px] border-b-[2px]">
            <p className="text-primary2 font-[600] leading-[28px] text-[20px]">
              Categories
            </p>
            <Button
              paddingX="12px"
              paddingY="6px"
              handleClick={() => {
                setView("create");
                setContent("");
                setPosition(-1);
              }}
            >
              <p>Create category</p>
            </Button>
          </div>
          <div className="w-full cflexsm gap-[16px]">
            <form className="w-[90%] rounded-[6px] px-[12px] py-[8px] flexsm gap-[12px] border-[2px] shadow-sm">
              <img
                src="/search-icon.svg"
                alt="search-icon"
                className="w-[20px] h-[20px] cursor-pointer"
              />
              <input
                name="searchQuery"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Search categories"
                className="outline-none w-full"
              />
            </form>
            <div className="w-full cflexsm text-[14px] font-[500] border-t-[2px] p-[24px]">
              <div className="w-full flexms rounded-[6px] p-[8px] gap-[8px] bg-secondary1 text-left">
                <img src="/bulb.svg" alt="bulb" />
                <p>
                  Categories and articles in the live Help Center follow the
                  sequence here.
                </p>
              </div>
              <div className="w-full mt-[34px] cflexsm gap-[10px] h-full overflow-y-auto">
                {loading ? (
                  <>
                    <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
                      {new Array(10).fill().map((x, index) => (
                        <div
                          key={index}
                          className="w-full flexsm gap-[5px] bg-white"
                        >
                          <Skeleton circle width={"20px"} height={"20px"} />
                          <Skeleton
                            containerClassName="w-full h-[80px] rounded-[10px]"
                            className="w-full h-[100vh] rounded-[10px]"
                            width={"100%"}
                            height={"70px"}
                            borderRadius={"10px"}
                          />
                        </div>
                      ))}
                    </SkeletonTheme>
                  </>
                ) : (
                  filteredCategories?.map((category, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full flexsm gap-[5px] bg-white"
                        draggable
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragOver={onDragOver}
                        onDrop={(e) => onDrop(e, index)}
                        onClick={() => {
                          setView("create");
                          setContent(category);
                          setPosition(index);
                        }}
                      >
                        <div className="w-[20px] h-[20px] text-[12px] bg-gray-200 flexmm rounded-full">
                          {index + 1}
                        </div>
                        <div className="relative w-full flexbm rounded-[10px] border-[2px] px-[17px] py-[16px] gap-[5px] cursor-pointer hover:bg-[#f5f5f5]">
                          {category.status.toLowerCase() === "archived" && (
                            <div className="absolute top-[2px] text-[10px] right-[28px] px-[10px] py-[2px] rounded-[5px] bg-[#EAEAEA]">
                              <p>ARCHIVED</p>
                            </div>
                          )}
                          <div>
                            <p className="line-clamp-1">{category.name}</p>
                            <p className="text-[12px] text-[#808080]">
                              {category.description}
                            </p>
                          </div>
                          <img
                            src="/move.svg"
                            alt="move"
                            draggable={false}
                            className="cursor-pointer"
                            onDragStart={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[80%] h-[90vh] cflexmm gap-[25px]">
          {view === "" && (
            <>
              <div className="cflexmm text-[16px] font-[400] text-[#2C2B2B]">
                <img
                  className="w-[170px] h-[170px]"
                  src="/category-box.svg"
                  alt="box"
                />
                <p>Your categories appear here.</p>
              </div>
              <div
                className="bg-primary2 text-white rounded-[10px] px-[12px] py-[6px] gap-[8px] flexmm font-[500] text-[14px] cursor-pointer"
                onClick={() => {
                  setView("create");
                  setContent("");
                  setPosition(-1);
                }}
              >
                <Plus size="20px" />
                <p>Create new category</p>
              </div>
            </>
          )}

          {content === "" && view === "create" && (
            <CategoryContent
              category={content}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          )}

          {categories.map((category, index) => {
            return (
              <>
                {content === category && view === "create" && (
                  <CategoryContent
                    category={content}
                    setRefresh={setRefresh}
                    refresh={refresh}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GeneralContent;
