import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import ErrorPopUp from "../components/ErrorPopUp";
import { editUserProfile } from "../services/request";
import TermsPrivacy from "./collections/TermsPrivacy";
// import { next } from "slate";

const Info = () => {
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    companySize: "",
    companyWebsite: "",
  });
  const [popUp, setPopUp] = useState(false);
  const [err, setErr] = useState(false);
  const urlRegex =
    /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
  const [changing, setChanging] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setUserProfile({ ...userProfile, [name]: value });
    if (name === "companySize") {
      setUserProfile({ ...userProfile, [name]: parseInt(value) });
    }
    setChanging(!changing);
  };

  useEffect(() => {
    if (
      userProfile["firstName"] &&
      userProfile["lastName"] &&
      userProfile["companySize"] &&
      urlRegex.test(userProfile["companyWebsite"])
    ) {
      setValid(true);
      setErr(false);
    }

    if (
      userProfile["companyWebsite"] &&
      !urlRegex.test(userProfile["companyWebsite"])
    ) {
      setErr(true);
    } else {
      setErr(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changing]);

  const handleSubmit = async (e) => {
    const alpsToken = localStorage.getItem("alpsToken");
    e.preventDefault();
    if (valid) {
      setLoading(true);
      await editUserProfile(alpsToken, userProfile);
      setLoading(false);
    } else if (!valid) {
      setPopUp(true);
    }
  };
  return (
    <>
      {popUp && (
        <ErrorPopUp
          type="link"
          head="Not Quite"
          text="Kindly fill in all the fields"
          setPopUp={setPopUp}
        />
      )}
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] p-[50px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handleSubmit}
        >
          <p className="text-[24.63px] font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Tell us about your work
          </p>
          <div className="w-full flexbm mb-[13px] gap-[14px]">
            <input
              type="text"
              name="firstName"
              value={userProfile.firstName}
              onChange={handleChange}
              placeholder="First name"
              className=" border-[2px] shadow-sm w-1/2 p-[10px] rounded-[8px] outline-none"
            />
            <input
              type="text"
              name="lastName"
              value={userProfile.lastName}
              onChange={handleChange}
              placeholder="Last name"
              className=" border-[2px] shadow-sm w-1/2 p-[10px] rounded-[8px] outline-none"
            />
          </div>
          <input
            type="number"
            name="companySize"
            value={userProfile.companySize}
            onChange={handleChange}
            placeholder="How big is your company?"
            className=" border-[2px] border-b-black shadow-sm w-full p-[10px] mb-[13px] rounded-[8px] outline-none"
          />
          <input
            type="text"
            name="companyWebsite"
            value={userProfile.companyWebsite}
            onChange={handleChange}
            placeholder="What's your company's website?"
            className=" border-[2px] border-b-black shadow-sm w-full p-[10px] mb-[13px] rounded-[8px] outline-none"
          />
          <div className="w-full flexss py-[5px]">
            {err && <p className="text-[12px] text-red-800">!invalid url</p>}
          </div>

          <button
            type="submit"
            className="text-[16px] mb-[24px] text-white font-[600] border-none px-[16px] py-[8px] flexmm rounded-[8px] w-full bg-buttonColor"
          >
            {loading ? <Loader /> : <p className="leading-[22px]">Continue</p>}
          </button>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default Info;
