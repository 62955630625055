import React from "react";
import { Search } from "heroicons-react";

const HeroSection = ({ designInfo }) => {
  return (
    <>
      <div
        className="w-full mt-[70px] pt-[50px] px-[112px] pb-[112px] cflexmm bg-mainColor font-inter"
        style={{
          backgroundImage: `url(${
            designInfo.heroSectionBackground
              ? designInfo.heroSectionBackground.base64
              : ""
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",    
          fontFamily: designInfo.textFont,      
        }}
      >
        <div className="w-full cflexmm gap-[48px]">
          <div
            className={`cflexmm w-full gap-[16px] text-center`}
            style={{ color: designInfo.heroSectionTextColour }}
          >
            <h1 className="text-[52px] font-[700]">
              {designInfo?.heroSectionHeadline}
            </h1>
            <p className="text-[18px]">{designInfo?.heroSectionBodyCopy}</p>
          </div>
          <div className="w-full flexmm">
            <div className="pl-[24px] pr-[10px] py-[8px] flexsm w-[800px] h-[56px] rounded-full gap-[8px] bg-white flex-shrink">
              <Search size="24px" color="#A3A3A3" className="cursor-pointer" />
              <input
                type="text"
                className="h-[22px] outline-none border-none w-[90%]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
