import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "heroicons-react";
import SearchModal from "../../components/SearchModal";
import Settings from "../../components/Settings";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import { getUserData, getUserProfile } from "../../services/request";

const NavBar = ({ active }) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [sModal, setSModal] = useState(false);
  const [userData, setUserData] = useState("");
  const [userProfile, setUserProfile] = useState("");

  const navItems = [
    {
      name: "Collections",
      link: "collections",
    },
    {
      name: "Articles",
      link: "articles",
    },
    {
      name: "Guides",
      link: "guides",
    },
    // {
    //   name: "API & References",
    //   link: "api&references",
    // },
  ];

  const getUser = async () => {
    const alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      let userData = await getUserData(alpsToken);
      if (userData?.isVerified) {
        let userProfile = await getUserProfile(alpsToken);
        setUserProfile(userProfile);
      }
      if (userData?.firstName && userData.lastName) {
        setUserData(userData);
      } else {
        navigate("/info");
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpenModal && <SearchModal setIsOpenModal={setIsOpenModal} />}
      {sModal && (
        <Settings
          setSModal={setSModal}
          userData={userData}
          userProfile={userProfile}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      <div className="fixed z-50 top-0 left-0 w-full flexbm px-[20px] py-[15px] bg-primary2 gap-[20px] font-inter">
        <ul
          className="flexsm gap-[30px] text-[16px] text-[#F2F2F2]"
          style={{ listStyleType: "none" }}
        >
          {navItems.map((item, index) => {
            return (
              <div
                key={index}
                className="cflexmm gap-[5px] cursor-pointer transition-all"
                onClick={() => {
                  window.location.pathname = `/${item.link}`;
                }}
              >
                <li
                  className={`px-[9px] py-[4px] rounded-[4px] ${
                    active === item.name && "bg-[#4E4D4D]"
                  } transition-all`}
                >
                  {item.name}
                </li>
                {active === item.name && (
                  <div className="w-full h-[3px] rounded-full bg-white" />
                )}
              </div>
            );
          })}
          <div
            className="flexmm gap-[12px] cursor-pointer"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            <Search size="20px" /> <p>Search</p>
          </div>
        </ul>
        <div className="flexmm gap-[30px] text-[16px] text-[#F2F2F2]">
          <div
            className="cflexmm gap-[5px] cursor-pointer transition-all"
            onClick={() => {
              window.location.pathname = "/design";
            }}
          >
            <div
              className={`px-[9px] py-[4px] rounded-[4px] ${
                active === "Design" && "bg-[#4E4D4D]"
              } transition-all`}
            >
              Design
            </div>
            {active === "Design" && (
              <div className="w-full h-[3px] rounded-full bg-white" />
            )}
          </div>
          <div
            className="relative flexmm gap-[10px] px-[9px] py-[4px]"
            onClick={() => {
              setModal(!modal);
            }}
          >
            {modal && (
              <>
                <div className="fixed z-[80] h-full w-full shadow-md bg-[#000000]/10 flexes top-0 left-0 pt-[85px] pr-[20px]">
                  <div
                    className="rounded-[10px] bg-[#222222] w-[186px] flex-shrink cflexss p-[10px] gap-[6px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModal(false);
                    }}
                  >
                    <div
                      className="rounded-[10px] px-[9px] py-[6px] w-full hover:bg-[#4E4D4D] cursor-pointer"
                      onClick={() => {
                        setSModal(true);
                      }}
                    >
                      <p>Settings</p>
                    </div>
                    <div
                      className="rounded-[10px] px-[9px] py-[6px] w-full hover:bg-[#4E4D4D] cursor-pointer"
                      onClick={() => {
                        localStorage.clear("alpsToken");
                        window.location.pathname = "/login";
                      }}
                    >
                      <p>Log out</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {userData ? (
              <>
                <div className="flexmm gap-[14px] cursor-pointer">
                  <div className="w-[35px] h-[35px] text-[14px] rounded-full bg-green-600 text-white font-bold flexmm">
                    {userData?.firstName?.charAt(0)}{" "}
                    {userData?.lastName?.charAt(0)}
                  </div>
                  <div className="cflexss text-[12px]">
                    <p className="font-medium">
                      {userData?.firstName} {userData?.lastName}
                    </p>
                    <p className="text-[#656667]">{userData?.email}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
                  <div className="w-full flexmm gap-[14px]">
                    <Skeleton circle width={"35px"} height={"35px"} />
                    <div className="cflexss">
                      <Skeleton
                        width={"160px"}
                        height={"10px"}
                        borderRadius={"12px"}
                      />
                      <Skeleton
                        width={"160px"}
                        height={"10px"}
                        borderRadius={"12px"}
                      />
                    </div>
                  </div>
                </SkeletonTheme>
              </>
            )}
            <div className="cursor-pointer">
              <img src="/settings.svg" alt="settings" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
