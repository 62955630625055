import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CogOutline,
  ChartBarOutline,
  ClockOutline,
  ArrowDownOutline,
  DocumentDownloadOutline,
} from "heroicons-react";
import AddContent from "./AddContent";
import ErrorPopUp from "../../components/ErrorPopUp";
import { getUserCategories, updateArticle } from "../../services/request";

const CreateArticle = ({ setContent, article, setArticle }) => {
  const navigate = useNavigate();
  const { articleID } = useParams();
  const [edited, setEdited] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showDetails1, setShowDetails1] = useState(true);  
  const [popUp, setPopUp] = useState(false);
  const [modal, setModal] = useState(null);
  const [categories, setCategories] = useState([]);
  const [articleId, setArticleId] = useState(`${article?.id || ""}`);
  const [articleCategory, setArticleCategory] = useState("");
  // const [loading, setLoading] = useState(false);

  const getCategories = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    }
    let data;    
    if (alpsToken) {      
      data = await getUserCategories(alpsToken);
      if (data) {
        setCategories(data);
        let categoryId = localStorage.getItem("alpsCategoryId");
        // if (categoryId) {
        //   linkAnArticle(categoryId);
        //   localStorage.removeItem("alpsCategoryId");
        // }
        let category = data.find(
          (category) => category.id === article.category
        );
        if (category) {
          setArticleCategory(category.name);
        }
        if (categoryId) {
          let category1 = data.find((category) => category.id === categoryId);
          setArticleCategory(category1.name);
          localStorage.removeItem("alpsCategoryId");
        }
      }      
    }
  };

  const MenuContent = [
    {
      name: "Settings",
      icon: <CogOutline size="20px" />,
    },
    {
      name: "Page analytics",
      icon: <ChartBarOutline size="20px" />,
    },
    {
      name: "Version history",
      icon: <ClockOutline size="20px" />,
    },
    {
      name: "Import",
      icon: <ArrowDownOutline size="20px" />,
    },
    {
      name: "Export",
      icon: <DocumentDownloadOutline size="20px" />,
    },
  ];

  const linkAnArticle = async (categoryId) => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (categoryId && articleId) {
      let data = await updateArticle(
        alpsToken,
        {
          category: categoryId,
        },
        articleId
      );
      if (data) {
        setPopUp(true);
        setModal(
          <ErrorPopUp
            type="success"
            head="Article linked!"
            text="Your article has been linked to the category successfully."
            setPopUp={setPopUp}
          />
        );        
      }
    }
  };

  const confirmExit = () => {
    setPopUp(true);
    setModal(
      <ErrorPopUp
        type="unsaved"
        head="Unsaved changes!"
        text="Are you sure you want to exit without saving?"
        buttonText="Yes"
        handleClick={() => {
          if (articleID) {
            navigate("/collections");            
          } else {
            setContent("articles");
            setArticle("");
          }
          setEdited(false);
          setPopUp(false);
        }}
        setPopUp={setPopUp}
      />
    );
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {popUp && modal}
      <div className="w-full flexss h-full mt-[70px] font-inter">
        <div className="w-[320px] h-[100vh] cflexss pb-[40px] border-r-[2px] text-primary1 flex-shrink overflow-y-auto">
          <div className="w-full p-[16px] flexbm border-b-[2px]">
            <button
              className="flexmm px-[12px] py-[6px] gap-[8px] rounded-[10px] text-[14px] font-medium bg-button2Color"
              onClick={() => {                
                if (articleID) {
                  if (edited) {
                    confirmExit();
                  } else {
                    navigate("/collections");
                  }
                } else {
                  if (edited) {
                    confirmExit();
                  } else {
                    setContent("articles");
                    setArticle("");
                  }
                }
              }}
            >
              <ChevronLeft />
              <p>Back</p>
            </button>
            {/* <button
              className="flexmm px-[12px] py-[6px] gap-[8px] rounded-[10px] text-[14px] font-medium bg-button2Color"
              onClick={() => {}}
            >
              <ChartBarOutline size="20px" />
              <p>Page analytics</p>
            </button> */}
            {/* <div className="flexmm gap-[8px] px-[12px] text-[14px] font-medium py-[6px] cursor-pointer">
              <Popover className="relative">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md bg-transparent px-0 py-0 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <DotsVertical
                        className={`${open ? "" : "text-opacity-70"}
                  ml-0 h-4 w-4 text-[#7d7d7d] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                        aria-hidden="true"
                        size="30px"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-[20] mt-0 left-[-60px] w-[192px] transform">
                        <div className="relative py-[8px] bg-white cflexss gap-[8px] flex-shrink shadow-xl">
                          {MenuContent.map((content, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="px-[16px] py-[8px] text-[16px] gap-[10px] flexsm font-medium cursor-pointer"
                                  onClick={() => {
                                    if (content.name === "Page analytics") {
                                      setAnalytics(true);
                                      close();
                                    } else if (content.name === "Settings") {
                                      setAnalytics(false);
                                      close();
                                    }
                                  }}
                                >
                                  {content.icon}
                                  <p>{content.name}</p>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <p>More Options</p>
            </div> */}
          </div>
          <div className="w-full cflexsm pb-[40px]">
            <div className="w-full flexss text-[15px] font-semibold p-[16px] text-gray-500">
              <p>SETTINGS</p>
            </div>
            <div className="w-full flexmm text-[14px] text-[#7C7D7E] font-normal border-t-[2px] p-[16px]">
              <div className="w-full cflexsm rounded-[8px] bg-button2Color">
                <div className="w-full flexbm text-[18px] p-[16px] font-semibold text-mainColor">
                  <p>Article Details</p>
                  {showDetails1 ? (
                    <ChevronDown
                      size="24px"
                      className="cursor-pointer"
                      onClick={() => {
                        setShowDetails1(false);
                      }}
                    />
                  ) : (
                    <ChevronRight
                      size="24px"
                      className="cursor-pointer"
                      onClick={() => {
                        setShowDetails1(true);
                      }}
                    />
                  )}
                </div>
                {showDetails1 && (
                  <div className="w-full cflexss gap-[24px] py-[16px] bg-white border-[2px] border-button2Color rounded-b-[8px]">
                    <div className="cflexss gap-[16px] px-[16px]">
                      <p className="text-[16px] font-semibold text-mainColor">
                        Add article to category
                      </p>
                      <p>
                        Add your article to a category for it to appear in the
                        help center.
                      </p>
                      <select
                        className="w-full px-[8px] py-[12px] border-[2px] rounded-[6px] cursor-pointer outline-none"
                        onChange={(e) => {
                          if (
                            articleId &&
                            e.target.value !== "Select category"
                          ) {
                            linkAnArticle(e.target.value);
                          }
                        }}
                      >
                        <option>{articleCategory}</option>
                        {categories?.map((category) => {
                          return (
                            <>
                              {articleCategory !== category.name && (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              )}
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="cflexss gap-[16px] px-[16px]">
                      <p className="text-[16px] font-semibold text-mainColor">
                        Visible to
                      </p>
                      <p>Control who can see this article in help center</p>
                      <select className="w-full px-[8px] py-[12px] border-[2px] rounded-[6px] cursor-pointer outline-none">
                        <option>Select who can see</option>
                      </select>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flexmm text-[14px] text-[#7C7D7E] font-normal border-t-[2px] p-[16px]">
              <div className="w-full cflexsm rounded-[8px] bg-button2Color">
                <div className="w-full flexbm text-[18px] p-[16px] font-semibold text-mainColor">
                  <p>Article analytics</p>
                  {showDetails ? (
                    <ChevronDown
                      size="24px"
                      className="cursor-pointer"
                      onClick={() => {
                        setShowDetails(false);
                      }}
                    />
                  ) : (
                    <ChevronRight
                      size="24px"
                      className="cursor-pointer"
                      onClick={() => {
                        setShowDetails(true);
                      }}
                    />
                  )}
                </div>
                {showDetails && (
                  <div className="w-full cflexss gap-[24px] pb-[16px] border-[2px] border-button2Color rounded-b-[8px]">
                    <div className="w-full cflexss gap-[6px]">
                      <div className="w-full bg-white px-[16px] cflexss text-[16px] gap-[6px] text-normal py-[16px]">
                        <p className=" font-semibold text-mainColor">
                          Created on
                        </p>
                        <p>Date</p>
                      </div>
                      <div className="py-[16px] px-[16px] bg-white flexbm w-full">
                        <div className="flexsm gap-[8px]">
                          <img src="/views.png" alt="" />
                          <p>Views</p>
                        </div>
                        <div className="w-[28px] h-[20px] rounded-full text-[12px] text-white flexmm bg-mainColor">
                          {article?.views || 0}
                        </div>
                      </div>
                      <div className="py-[16px] px-[16px] bg-white flexbm w-full">
                        <div className="flexsm gap-[8px]">
                          <img src="/likes.png" alt="" />
                          <p>Helpful</p>
                        </div>
                        <div className="w-[28px] h-[20px] rounded-full text-[12px] text-white flexmm bg-mainColor">
                          {article?.upVoteCount || 0}
                        </div>
                      </div>
                      <div className="py-[16px] px-[16px] bg-white flexbm w-full">
                        <div className="flexsm gap-[8px]">
                          <img src="./dislikes.png" alt="" />
                          <p>Not Helpful</p>
                        </div>
                        <div className="w-[28px] h-[20px] rounded-full text-[12px] text-white flexmm bg-mainColor">
                          {article.downVoteCount || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[80%] h-[100vh] overflowy-y-scroll cflexmm gap-[25px]">
          <AddContent
            id={articleID}
            articleId={articleId}
            setArticleId={setArticleId}
            setContent={setContent}
            article={article}
            setArticle={setArticle}
            setEdited={setEdited}
          />
        </div>
      </div>
    </>
  );
};

export default CreateArticle;
