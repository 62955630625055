import React, { useEffect } from "react";
// #10A37F
const ErrorPopUp = ({
  type,
  head,
  text,
  buttonText = "",
  handleClick,
  setPopUp,
}) => {
  let timeOut1;
  useEffect(() => {
    if (type !== "unsaved") {
      timeOut1 = setTimeout(() => {
        clearTimeout(timeOut1);
        setPopUp(false);
      }, [4000]);
    }
  }, []);
  return (
    <>
      <div
        className="fixed top-0 left-0 w-[100vw] z-[999] flexms pt-[100px] bg-black/20 h-full font-inter"
        onClick={() => {
          setPopUp(false);
          clearTimeout(timeOut1);
        }}
      >
        <div
          className={`rounded-[7px] bg-[#F5F5F5] px-[19px] max-w-[400px] flexmm gap-[10px] py-[10px] border-[0px] ${
            type === "auth" && "border-[#EA4335]"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flexms gap-[15px] text-[#656667] text-[16px]">
            {type === "auth" && (
              <img
                src="/thinkingEmoji.svg"
                alt="error"
                className="w-[24px] h-[24px]"
              />
            )}
            {type === "success" && (
              <img
                src="/celebrate.svg"
                alt="success"
                className="w-[24px] h-[24px]"
              />
            )}
            {type === "brandError" && (
              <img src="/brand.svg" alt="error" className="w-[24px] h-[24px]" />
            )}
            {type === "link" && (
              <img src="/hand.svg" alt="error" className="w-[24px] h-[24px]" />
            )}
            {type === "unsaved" && (
              <img src="/hand.svg" alt="error" className="w-[24px] h-[24px]" />
            )}
            <div className="cflexss gap-[6px]">
              <p className="text-[18px] font-semibold">{head}</p>
              <p className="]">{text}</p>
            </div>
          </div>
          {buttonText && (
            <button
              className="rounded-[10px] px-[12px] py-[6px] text-[14px] bg-[#F5F5F5]"
              onClick={() => {
                handleClick();
              }}
            >
              <p>{buttonText}</p>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ErrorPopUp;
