import React, { useState } from "react";
import {
  setBrand,
  editUserProfile,
  updateCustomDomain,
  updateGoogleTracker,
} from "../services/request";
import Loader2 from "./Loader/Loader2";
import Button from "./Button";
import UpdatePassword from "./UpdatePassword";
import PlanBillings from "./PlanBillings";
import AlpsPro from "./AlpsPro";

const Settings = ({
  setSModal,
  userData,
  userProfile,
  refresh,
  setRefresh,
}) => {
  const prevName = userProfile?.name;
  const [view, setView] = useState("account");
  const [pLoading, setPLoading] = useState(false);
  const [updateDomain, setUpdateDomain] = useState(false);
  const [updateCustom, setUpdateCustom] = useState(false);
  const [updateTrackingId, setUpdateTrackingId] = useState(false);
  const [changing, setChanging] = useState(false);
  const [update, setUpdate] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pro, setPro] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    email: userData?.email || "",
  });

  const [publicInfo, setPublicInfo] = useState({
    domain: userProfile?.name || "",
    customDomain: "",
    googleTrackingID: userProfile?.googleTrackingId || "",
  });

  const handleAccountChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAccountInfo({ ...accountInfo, [name]: value });
    setChanging(true);
  };

  const handlePublicChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPublicInfo({ ...publicInfo, [name]: value });
    if (name === "customDomain") {
      setUpdateCustom(true);
    }
    if (name === "domain") {
      setUpdateDomain(true);
    }

    if (name == "googleTrackingID") {
      setUpdateTrackingId(true);
    }
  };

  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    const alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      setChanging(false);
      setLoading(true);
      await editUserProfile(
        alpsToken,
        {
          firstName: accountInfo.firstName,
          lastName: accountInfo.lastName,
        },
        true
      );
      setLoading(false);
      setRefresh(!refresh);
    }
  };

  const handlePublicUpdate = async (e) => {
    e.preventDefault();
    const alpsToken = localStorage.getItem("alpsToken");
    userData.isVerified && setUpdateDomain(false);
    userData.isVerified && setUpdateCustom(false);
    userData.isVerified && setUpdateTrackingId(false);
    setPLoading(true);
    if (updateDomain && alpsToken && userData.isVerified) {
      await setBrand(
        alpsToken,
        { name: publicInfo.domain, previousName: prevName },
        "settings"
      );
    }
    if (
      updateCustom &&
      alpsToken &&
      userData.isVerified &&
      userData.isPremiumTier
    ) {
      await updateCustomDomain(alpsToken, {
        domain: publicInfo.customDomain,
      });
    }
    if (
      updateTrackingId &&
      alpsToken &&
      userData.isVerified &&
      userData.isPremiumTier
    ) {
      let data = await updateGoogleTracker(alpsToken, {
        googleTrackingID: publicInfo.googleTrackingID,
      });

      console.log(data);
    }
    setRefresh(!refresh);
    setPLoading(false);
  };

  return (
    <>
      {pro && (
        <AlpsPro
          setPro={setPro}
          email={userData?.email}
          publicInfo={publicInfo}
        />
      )}
      {update && (
        <UpdatePassword
          setUpdate={setUpdate}
          setPopUp={setPopUp}
          setModal={setModal}
        />
      )}
      {popUp && modal}
      <div
        className="fixed top-0 left-0 z-[100] w-full h-full bg-black/30 flexmm font-inter"
        onClick={() => {
          setSModal(false);
        }}
      >
        <div
          className="w-[890px] p-[16px] h-[530px] bg-white flexss mt-[80px] rounded-[6px] shadow-md flex-shrink"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="cflexss gap-[40px] pr-[15px] w-[220px] h-full border-r-[1px]">
            <div className="cflexss gap-[7px] w-full">
              <p className="font-semibold text-[12px] text-[#7C7D7E]">ME</p>
              <div
                className={`rounded-[10px] flexsm w-full px-[8px] py-[6px] text-[14px] text-[#4D4E51] ${
                  view === "account" && "bg-[#F5F5F5]"
                } font-medium gap-[8px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("account");
                }}
              >
                {/* <img
                  src="/profilePic.svg"
                  alt="profile-picture"
                  className="w-[18px] h-[18px] rounded-full"
                /> */}
                <div className="w-[27px] h-[27px] text-[12px] rounded-full bg-green-600 text-white font-bold flexmm">
                  {userData?.firstName?.charAt(0)}{" "}
                  {userData?.lastName?.charAt(0)}
                </div>
                <p>Account</p>
              </div>
            </div>

            <div className="cflexss gap-[7px] w-full">
              <p className="font-semibold text-[12px] text-[#7C7D7E]">
                WORKSPACE
              </p>
              <div
                className={`rounded-[10px] flexsm w-full px-[8px] py-[6px] text-[14px] text-[#4D4E51] ${
                  view === "publicSettings" && "bg-[#F5F5F5]"
                } font-medium gap-[8px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("publicSettings");
                }}
              >
                <img
                  src="/globeS.svg"
                  alt="setting-public"
                  className="w-[18px] h-[18px] rounded-full"
                />
                <p>Public Settings</p>
              </div>
              <div
                className={`rounded-[10px] flexsm w-full px-[8px] py-[6px] text-[14px] text-[#4D4E51] ${
                  view === "planBilling" && "bg-[#F5F5F5]"
                } font-medium gap-[8px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("planBilling");
                }}
              >
                <img
                  src="/plan-billing.svg"
                  alt="plan-billing"
                  className="w-[18px] h-[18px] rounded-full"
                />
                <p>Plan and Billings</p>
              </div>
            </div>
          </div>
          <div className="w-full h-full py-[10px] pl-[32px] pr-[10px]">
            {view === "account" && (
              <>
                <form
                  className="w-full h-full cflexbs gap-[23px]"
                  onSubmit={handleAccountUpdate}
                >
                  <div className="cflexss w-full h-full gap-[23px]">
                    <p className="text-[#4D4E51] text-[16px] font-semibold">
                      Account
                    </p>
                    <div className="flexsm gap-[14px] flex-shrink flex-wrap">
                      <div className="cflexss text-[#7C7D7E] text-[12px] gap-[4px]">
                        <p>First name</p>
                        <input
                          type="text"
                          name="firstName"
                          value={accountInfo["firstName"]}
                          className="w-[260px] px-[12px] rounded-[4px] text-black py-[10px] outline-none border-[1px]"
                          onChange={handleAccountChange}
                        />
                      </div>
                      <div className="cflexss text-[#7C7D7E] text-[12px] gap-[4px]">
                        <p>Last name</p>
                        <input
                          type="text"
                          name="lastName"
                          value={accountInfo["lastName"]}
                          className="w-[260px] px-[12px] rounded-[4px] text-black py-[10px] outline-none border-[1px]"
                          onChange={handleAccountChange}
                        />
                      </div>
                    </div>
                    <div className="flexbm w-full">
                      <div className="cflexms text-black text-[14px] font-medium">
                        <p>Email</p>
                        <p className="text-[#7C7D7E] text-[12px]">
                          {accountInfo["email"]}
                        </p>
                      </div>
                      <div className="flexmm gap-[12px]">
                        <p className="text-[14px] text-gray-300 font-medium">
                          Change email
                        </p>
                        {!userData?.isVerified && (
                          <Button
                            text="14px"
                            handleClick={() => {
                              window.location.href =
                                "https://mail.google.com/mail/u/0/#advanced-search/query=tryalps.com&isrefinement=true";
                            }}
                          >
                            <p>Verify your email</p>
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="flexbm w-full">
                      <div className="cflexms text-black text-[14px] font-medium">
                        <p>Pasword</p>
                        <p className="text-[#7C7D7E] text-[12px]">
                          Set a permanent password to login to your account.
                        </p>
                      </div>
                      <p
                        className="text-[14px] text-[#2C2B2B] font-medium cursor-pointer"
                        onClick={() => {
                          setUpdate(true);
                        }}
                      >
                        Update password
                      </p>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`${
                      changing
                        ? "bg-mainColor text-white"
                        : "bg-[#F5F5F5] text-mainColor"
                    } hover:bg-mainColor hover:text-white mb-0 rounded-[10px] px-[12px] py-[6px] flexmm text-[14px] font-medium transition duration-200`}
                  >
                    {loading ? (
                      <div className="flexmm gap-[10px]">
                        <Loader2 /> <p>Update</p>
                      </div>
                    ) : (
                      <p>Update</p>
                    )}
                  </button>
                </form>
              </>
            )}
            {view === "publicSettings" && (
              <>
                <form
                  className="w-full h-full cflexbs gap-[23px]"
                  onSubmit={handlePublicUpdate}
                >
                  <div className="cflexss w-full h-full gap-[23px]">
                    <p className="text-[#4D4E51] text-[16px] font-semibold">
                      Public Settings
                    </p>
                    <div className="cflexss text-[#7C7D7E] text-[12px] gap-[4px]">
                      <p>Domain</p>
                      <input
                        type="text"
                        name="domain"
                        value={publicInfo["domain"]}
                        className="w-[260px] px-[12px] rounded-[4px] text-black py-[10px] outline-none border-[1px]"
                        onChange={handlePublicChange}
                      />
                      {!userData.isVerified && (
                        <p className="text-red-800">
                          Verify your email in the account settings to get a
                          domain name
                        </p>
                      )}
                      <p>
                        Your help center is hosted under https://
                        {publicInfo["domain"]}.tryalps.app
                      </p>
                    </div>
                    <div className="cflexss text-[#7C7D7E] text-[12px] gap-[4px]">
                      <div className="flexsm gap-[10px]">
                        <p>Custom domain</p>
                        <div className="bg-[#B4DFBF] flexmm text-[#34A853] rounded-[22px] px-[8px] py-[2px]">
                          <p>Pro</p>
                        </div>
                      </div>
                      <div className="w-full flexsm gap-[6px]">
                        <input
                          type="text"
                          name="customDomain"
                          value={publicInfo["customDomain"]}
                          className="w-[260px] px-[12px] rounded-[4px] text-black py-[10px] outline-none border-[1px]"
                          onChange={handlePublicChange}
                        />
                        {userData.isFreeTier && (
                          <Button
                            text="14px"
                            paddingX="12px"
                            paddingY="6px"
                            handleClick={() => {
                              setPro(true);
                            }}
                          >
                            <p>Elevate your plan</p>
                          </Button>
                        )}
                      </div>
                    </div>

                    <div className="cflexss text-[#7C7D7E] text-[12px] gap-[4px]">
                      <div className="flexsm gap-[10px]">
                        <p>Google Tracking ID</p>
                        <div className="bg-[#B4DFBF] flexmm text-[#34A853] rounded-[22px] px-[8px] py-[2px]">
                          <p>Pro</p>
                        </div>
                      </div>
                      <div className="w-full flexsm gap-[6px]">
                        <input
                          type="text"
                          name="googleTrackingID"
                          value={publicInfo["googleTrackingID"]}
                          className="w-[260px] px-[12px] rounded-[4px] text-black py-[10px] outline-none border-[1px]"
                          onChange={handlePublicChange}
                        />
                        {userData.isFreeTier && (
                          <Button
                            text="14px"
                            paddingX="12px"
                            paddingY="6px"
                            handleClick={() => {
                              setPro(true);
                            }}
                          >
                            <p>Get Access</p>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`${
                      (updateCustom && userData.isPremiumTier) ||
                      updateDomain ||
                      (updateTrackingId && userData.isPremiumTier)
                        ? "bg-mainColor text-white"
                        : "bg-[#F5F5F5] text-mainColor"
                    } mb-0 rounded-[10px] px-[12px] hover:bg-mainColor hover:text-white py-[6px] flexmm text-[14px] font-medium transition ${
                      !userData.isVerified &&
                      "cursor-not-allowed bg-mainColor/40 hover:bg-mainColor/40"
                    } duration-200`}
                  >
                    {pLoading ? (
                      <div className="flexmm gap-[10px]">
                        <Loader2 /> <p>Update</p>
                      </div>
                    ) : (
                      <p>Update</p>
                    )}
                  </button>
                </form>
              </>
            )}
            {view === "planBilling" && (
              <>
                <PlanBillings setPro={setPro} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
