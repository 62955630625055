import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight, DotsHorizontal } from "heroicons-react";
import { Paginated, GetPaginatedData } from "../../atomicComponents/Pagination";
import { Popover, Transition } from "@headlessui/react";
import MoveArticle from "../../components/MoveArticle";
import AddNewVideo from "../../components/AddNewVideo";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import { getPlaylistGuides } from "../../services/request";

const GuideTable = ({
  setIsOpenModal,
  setModalContent,
  guides,
  setGuides,
  playlistId,
  playlist,
  refresh,
  setRefresh,
  searchQuery,
  userProfile,
}) => {
  const MenuContent = [
    {
      name: "Edit video",
    },
    {
      name: "Move video",
    },
    {
      name: "View in help center",
    },
    {
      name: "Delete",
    },
  ];
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PAGINATION = 3;
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    // Fetch and display data for the selected page
  };

  const onDragStart = (e, index) => {    
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {    
    e.preventDefault();
  };

  const onDrop = (e, newIndex) => {    
    const draggedIndex = e.dataTransfer.getData("index");
    const newItems = [...guides];
    const [removed] = newItems.splice(draggedIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setGuides(newItems);    
  };

  const getGuides = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    let data = await getPlaylistGuides(playlistId, alpsToken);    
    setGuides(data);
    setPageCount(Paginated(data, PAGINATION));
  };

  useEffect(() => {
    if (playlistId) {
      getGuides();
    }
  }, [playlistId, refresh]);

  const filteredGuides = guides?.filter((guide) =>
    guide.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <div className="w-full cflexss gap-[16px]">
        {guides.length > 0 ? (
          GetPaginatedData(currentPage, PAGINATION, filteredGuides).map(
            (guide, index) => {
              return (
                <>
                  <div
                    key={index}
                    draggable
                    onDragStart={(e) => onDragStart(e, index)}
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, index)}
                    className="cursor-pointer w-full bg-white hover:bg-[#EEEEEE] flexss gap-[10px] rounded-[10px] shadow-md border-[1px] p-[10px] transtion-all duration-150"
                    onClick={() => {
                      setIsOpenModal(true);
                      setModalContent(
                        <AddNewVideo
                          setIsOpenModal={setIsOpenModal}
                          playlistId={playlistId}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          guide={guide}
                        />
                      );
                    }}
                  >
                    <div className="cflexss gap-[35px]">
                      <div className="w-[20px] h-[20px] text-[12px] bg-gray-200 flexmm rounded-full">
                        {index + 1}
                      </div>
                      <img src="/move1.svg" alt="move-icon" />
                    </div>
                    <img
                      src={guide.thumbnail}
                      alt={`${guide.thumbnail}-${index + 1}`}
                      className="min-w-[164px] h-[116px] rounded-[20px]"
                    />
                    <div
                      className="cflexss gap-[14px] text-start"
                      style={{ width: "calc(100% - 200px)" }}
                    >
                      <p className="font-medium text-[16px]">{guide.title}</p>
                      <p className="font-normal text-[14px] line-clamp-3">
                        {guide.summary}
                      </p>
                    </div>
                    <div className="flexmm">
                      <Popover className="relative">
                        {({ open, close }) => (
                          <>
                            <Popover.Button
                              className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md bg-transparent px-0 py-0 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                              <DotsHorizontal
                                className={`${open ? "" : "text-opacity-70"}
                  ml-0 h-4 w-4 text-[#7d7d7d] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute right-0 z-[50] mt-0 w-[192px] transform">
                                <div className="overflow-hidden rounded-md bg-white shadow-xl">
                                  {MenuContent.map((content, index) => {
                                    return (
                                      <>
                                        <p
                                          key={index}
                                          className={`px-[16px] py-[8px] text-[14px] ${
                                            content.name === "Delete" &&
                                            "text-red-600"
                                          } font-medium cursor-pointer hover:bg-[#f5f5f5]`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (content.name === "Move guide") {
                                              setIsOpenModal(true);
                                              setModalContent(
                                                <MoveArticle
                                                  setIsOpenModal={
                                                    setIsOpenModal
                                                  }
                                                />
                                              );
                                            } else if (
                                              content.name === "Edit video"
                                            ) {
                                              setIsOpenModal(true);
                                              setModalContent(
                                                <AddNewVideo
                                                  setIsOpenModal={
                                                    setIsOpenModal
                                                  }
                                                  playlistId={playlistId}
                                                  setRefresh={setRefresh}
                                                  refresh={refresh}
                                                  guide={guide}
                                                />
                                              );
                                            } else if (
                                              content.name ===
                                                "View in help center" &&
                                              userProfile &&
                                              playlist.status.toLowerCase() ===
                                                "published"
                                            ) {
                                              window.open(`https://${
                                                userProfile?.name
                                              }.tryalps.app/guide/${playlist.name.replace(
                                                / /g,
                                                "-"
                                              )}`, '_blank');
                                            }
                                            close();
                                          }}
                                        >
                                          {content.name}
                                        </p>
                                      </>
                                    );
                                  })}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </div>
                  </div>
                </>
              );
            }
          )
        ) : (
          <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
            {new Array(3).fill().map((x, index) => (
              <Skeleton
                key={index}
                containerClassName="w-full h-[120px] rounded-[10px]"
                className="w-full h-[100vh]"
                width={"100%"}
                height={"120px"}
                borderRadius={"10px"}
              />
            ))}
          </SkeletonTheme>
        )}
      </div>
      <div className="w-full flexem mt-[10px]">
        <ReactPaginate
          previousLabel={<ArrowLeft size="20px" />}
          previousClassName={"p-[10px] border-[2px] rounded-l-[10px]"}
          nextClassName={"p-[10px] border-[2px] rounded-r-[10px]"}
          nextLabel={<ArrowRight size="20px" />}
          breakLabel={"..."}
          breakClassName={"w-full p-[10px]"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          pageClassName={"px-[20px] py-[10px] border-[2px]"}
          onPageChange={handlePageClick}
          containerClassName={"text-[14px] lg:text-[12px] flexem"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default GuideTable;
