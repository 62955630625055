import React from "react";
import ArticleContent from "./Pages/articles/ArticleContent";
import ProductLandingPage from "./Pages/LandingPage/ProductLandingPage";
import LandingPage from "./Pages/LandingPage/LandingPage";
import GuidesContent from "./Pages/guides/GuidesContent";
import DesignContent from "./Pages/design/DesignContent";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Info from "./Pages/Info";
import AddBrand from "./Pages/AddBrand";
import NavBar from "./Pages/collections/NavBar";
import Forgot from "./Pages/Forgot";
import Reset from "./Pages/Reset";
import VerifyAccount from "./Pages/VerifyAccount";
import PrivacyPolicy from "./Pages/privacy-policy";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import GeneralContent from "./Pages/collections/GeneralContent";
import HomePage from "./Pages/helpcenter/HomePage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<LandingPage />} />
      <Route path="/product" element={<ProductLandingPage />} />
      <Route path="/helpcenter" element={<HomePage />} />
      <Route path="/collections" element={<GeneralContent />} />
      <Route path="/collections/:collectionID" element={<GeneralContent />} />
      <Route path="/articles" element={<ArticleContent />} />
      <Route path="/articles/:articleID" element={<ArticleContent />} />
      <Route path="/guides" element={<GuidesContent />} />
      <Route path="/guides/:guidesID" element={<GuidesContent />} />
      <Route path="/design" element={<DesignContent />} />
      <Route path="/verify-account" element={<VerifyAccount />} />      
      <Route
        path="/api&references"
        element={
          <>
            <NavBar active="api&references" />
            <h1 className="mt-[70px]">API & References</h1>
          </>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/Info" element={<Info />} />
      <Route path="add-brand" element={<AddBrand />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset-password" element={<Reset />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
