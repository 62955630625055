import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Body from "./Body";
import Footer from "./Footer";

const HomePage = ({ designInfo }) => {
  return (
    <>
      <div className="relative cflexss w-full font-inter">
        <Header designInfo={designInfo} />
        <HeroSection designInfo={designInfo} />
        <Body designInfo={designInfo} />
        <Footer designInfo={designInfo} />
      </div>
    </>
  );
};

export default HomePage;
