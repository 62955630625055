import React, { useEffect } from "react";
import Loader2 from "../components/Loader/Loader2";
import { Link } from "react-router-dom";
import { verifyUserEmail } from "../services/request";

const VerifyAccount = () => {
  const getUrlData = async () => {
    const url = window.location.href;
    // Extract the query string from the URL
    const queryString = url.split("?")[1];

    // Extract the token and email using regular expressions
    const tokenRegex = /token=([^&]+)/;
    const emailRegex = /email=([^&]+)/;

    const tokenMatch = queryString.match(tokenRegex);
    const emailMatch = queryString.match(emailRegex);

    const token = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
    const email = emailMatch ? decodeURIComponent(emailMatch[1]) : null;
    await verifyUserEmail(token, { email: email });
  };

  useEffect(() => {
    getUrlData();
  }, []);
  return (
    <>
      <div className="w-full h-[100vh] cflexsm gap-[30px] text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form className="m-[10px] px-[40px] h-full w-[400px] cflexmm flex-shrink">
          <p className="text-[29.63px] text-center font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Account verification in progress...
          </p>
          <Loader2 />
        </form>
      </div>
    </>
  );
};

export default VerifyAccount;
