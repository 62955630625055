import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../collections/NavBar";
import Button from "../../components/Button";
import EachArticle from "./EachArticle";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../../../node_modules/react-loading-skeleton/dist/skeleton.css";
import CreateArticle from "./CreateArticle";
import { getAllArticles, getStatusArticles } from "../../services/request";

const ArticleContent = () => {
  const { articleID } = useParams();
  const [view, setView] = useState("All Articles");
  const [content, setContent] = useState("articles");
  const [article, setArticle] = useState("");
  const [articles, setArticles] = useState([]);
  const [draft, setDraft] = useState([]);
  const [archived, setArchived] = useState([]);
  const [published, setPublished] = useState([]);
  const [loading, setLoading] = useState(false);

  const getArticles = async (alpsToken) => {
    setLoading(true);
    let data1 = await getAllArticles(alpsToken);
    setArticles(data1);
    if (articleID) {      
      let article = data1.find((article) => article.id === articleID);      
      setContent("create article");
      setArticle(article);
    }
    let data2 = await getStatusArticles("published", alpsToken);
    setPublished(data2);
    let data3 = await getStatusArticles("draft", alpsToken);
    setDraft(data3);
    let data4 = await getStatusArticles("archived", alpsToken);
    setArchived(data4);
    setLoading(false);
  };
  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    let create = localStorage.getItem("alpsCreateArticle");
    if (create) {
      setContent("create article");
      localStorage.removeItem("alpsCreateArticle");
    }
    if (!alpsToken) {
      window.location.pathname = "/login";
    }
    let userData = localStorage.getItem("userData");
    let userJson;
    if (userData) {
      userJson = JSON.parse(userData);
      getArticles(alpsToken);
    }
  }, [articleID]);

  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    // let userData = localStorage.getItem("userData");
    // let userJson;
    if (content === "articles" && articles.length > 0) {
      // userJson = JSON.parse(userData);
      getArticles(alpsToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
  return (
    <>
      <NavBar active="Articles" />
      {content === "articles" && (
        <div className="w-full flexss h-full mt-[70px] font-inter">
          <div className="w-[320px] h-[90vh] cflexss gap-[14px] pb-[40px] border-r-[2px] text-primary1 flex-shrink overflow-y-auto">
            <div className="w-full px-[24px] py-[16px] flexbm gap-[16px] border-b-[2px]">
              <p className="text-primary2 font-[600] leading-[28px] text-[20px]">
                Articles
              </p>
              <Button
                paddingX="12px"
                paddingY="6px"
                handleClick={() => {
                  setContent("create article");
                }}
              >
                <p>Create article</p>
              </Button>
            </div>
            <div className="w-full cflexmm text-[16px] font-medium">
              <div
                className={`w-[85%] flexbm flex-shrink ${
                  view === "All Articles" && "bg-[#F5F5F5]"
                } px-[14px] py-[12px] rounded-[6px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("All Articles");
                }}
              >
                <p>All Articles</p>
                <div className="w-[20px] h-[20px] text-[12px] bg-[#F5F5F5] flexmm rounded-full">
                  {loading ? (
                    <SkeletonTheme baseColor="#f5f5f5" highlightColor="gray">
                      <Skeleton circle width={"20px"} height={"20px"} />
                    </SkeletonTheme>
                  ) : (
                    articles.length
                  )}
                </div>
              </div>
              <div
                className={`w-[85%] flexbm flex-shrink ${
                  view === "Published" && "bg-[#F5F5F5]"
                } px-[14px] py-[12px] rounded-[6px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("Published");
                }}
              >
                <p>Published</p>
                <div className="w-[20px] h-[20px] text-[12px] bg-[#F5F5F5] flexmm rounded-full">
                  {loading ? (
                    <SkeletonTheme baseColor="#f5f5f5" highlightColor="gray">
                      <Skeleton circle width={"20px"} height={"20px"} />
                    </SkeletonTheme>
                  ) : (
                    published.length
                  )}
                </div>
              </div>
              <div
                className={`w-[85%] flexbm flex-shrink ${
                  view === "Draft" && "bg-[#F5F5F5]"
                } px-[14px] py-[12px] rounded-[6px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("Draft");
                }}
              >
                <p>Draft</p>
                <div className="w-[20px] h-[20px] text-[12px] bg-[#F5F5F5] flexmm rounded-full">
                  {loading ? (
                    <SkeletonTheme baseColor="#f5f5f5" highlightColor="gray">
                      <Skeleton circle width={"20px"} height={"20px"} />
                    </SkeletonTheme>
                  ) : (
                    draft.length
                  )}
                </div>
              </div>
              <div
                className={`w-[85%] flexbm flex-shrink ${
                  view === "Archived" && "bg-[#F5F5F5]"
                } px-[14px] py-[12px] rounded-[6px] hover:bg-[#F5F5F5] cursor-pointer`}
                onClick={() => {
                  setView("Archived");
                }}
              >
                <p>Archived</p>
                <div className="w-[20px] h-[20px] text-[12px] bg-[#F5F5F5] flexmm rounded-full">
                  {loading ? (
                    <SkeletonTheme baseColor="#f5f5f5" highlightColor="gray">
                      <Skeleton circle width={"20px"} height={"20px"} />
                    </SkeletonTheme>
                  ) : (
                    archived.length
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[80%] h-[90vh] overflow-y-auto flexss pb-[40px] gap-[25px]">
            {view === "All Articles" && (
              <EachArticle
                articles={articles}
                setArticles={setArticles}
                setArticle={setArticle}
                setContent={setContent}
                title={view}
              />
            )}
            {view === "Published" && (
              <EachArticle
                articles={published}
                setArticles={setPublished}
                setArticle={setArticle}
                setContent={setContent}
                title={view}
              />
            )}
            {view === "Draft" && (
              <EachArticle
                articles={draft}
                setArticles={setDraft}
                setArticle={setArticle}
                setContent={setContent}
                title={view}
              />
            )}
            {view === "Archived" && (
              <EachArticle
                articles={archived}
                setArticles={setArchived}
                setArticle={setArticle}
                setContent={setContent}
                title={view}
              />
            )}
          </div>
        </div>
      )}
      {content === "create article" && (
        <CreateArticle
          setContent={setContent}
          article={article}
          setArticle={setArticle}
        />
      )}
    </>
  );
};

export default ArticleContent;
